<template>
  <div class="account-contain">
    <!-- 图片大于7m的时候 上传七牛是失败的，前端传给后台的是一个链接地址，后台 -->
    <div class="photo_contain">
      <div class="photo">
        <img :src="upImgUrl" alt="" />
        <div class="icon">
          <img src="~@ass/img/ico_ghtx@2x.png" alt="" />
        </div>

        <changePhotoimg
          :size="7"
          :isshow="true"
          url="/User/editUserSimpleInfo"
          @complete="complete"
          class="changePhotoimg"
        ></changePhotoimg>
      </div>
    </div>
    <!-- 修改密码区域 -->
    <div class="account-info">
      <div class="account-box">
        <div class="account-item mb">
          <span class="account-item-title">手机号</span>
          <span class="account-item-content">{{ accountPhone }}</span>
        </div>
        <div class="account-item mb">
          <span class="account-item-title">昵称</span>
          <span class="account-item-content inputlth">{{ accountName }}</span>
          <div class="modify" @click="toggleModifyConfirm()">修改</div>
        </div>
        <div class="account-item mb">
          <span class="account-item-title">密码</span>
          <input
            :type="accountInfo.upwd ? 'password' : 'text'"
            class="account-item-content inputlth"
            :value="accountInfo.upwd ? showPassword : '未设置密码'"
            style="border: none; background: #fff"
            disabled="disabled"
            maxlength="16"
          />
          <div class="modify" @click="togglePassConfirm()">修改</div>
        </div>
        <div class="account-item mb">
          <span class="account-item-title">绑定微信</span>
          <span class="account-item-content">
            {{ wx_bind ? '已绑定' : '未绑定' }}
          </span>
          <div class="modify" @click="bind_wx" v-if="!wx_bind">绑定</div>
          <div class="modify" @click="change_wx" v-if="wx_bind">更换</div>
        </div>
        <div class="awayScholl" @click="awayScholl">离开网校</div>
      </div>
    </div>
    <!--修改昵称-->
    <modifyName
      v-if="showDetail"
      :modifyAccountName="accountName"
      :uid="accountInfo.uid"
      @setModifyAccountName="getModifyAccountname"
      @close="showDetail = false"
    ></modifyName>

    <!-- 修改密码 -->
    <modifyPassword
      v-if="showPassDetail"
      :mobilePhone="accountPhone"
      :uid="accountInfo.uid"
      @setModifyAccountPassword="getModifyAccountPassword"
      @close="showPassDetail = false"
    ></modifyPassword>
    <!--离开网校-->
    <el-dialog
      title="离开网校"
      width="422px"
      :visible.sync="showAwayScholl"
      custom-class="awayScholl"
      :before-close="awaySchollClose"
    >
      <div style="margin: 20px 20px; margin-top: 0px">
        <p
          style="
            margin-bottom: 8px;
            font-size: 14px;
            color: rgba(102, 102, 102, 1);
            margin-top: 10px;
          "
        >
          离开后，该账号将无法登录当前网校，是否确认离开？
        </p>
        <el-input
          v-model.trim="awaySchollText"
          maxlength="4"
          show-word-limit
          placeholder="请输入“确认离开”"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="awaySchollClose">取 消</el-button>
        <el-button
          :style="`opacity:${awaySchollText === '确认离开' ? 1 : 0.5}`"
          type="primary"
          @click="awaySchollCallback"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!-- 绑定微信 -->
    <el-dialog
      title="账号绑定微信"
      :visible.sync="dialogVisible_wx"
      class="dialog_wx_hearder"
      width="800px"
    >
      <div class="dialog_wx">
        <div class="left">
          <div class="left_container">
            <img
              v-show="qrcode"
              :src="qrcode"
              style="width: 154px; heigth: 154px"
            />
            <div class="left_font">请使用要绑定的微信扫码</div>
            <div class="redcolor fs12 mt20">
              *注：扫码后如果没有关注公众号，点击关注即可
            </div>
          </div>
        </div>
        <div class="right">
          <img src="../../assets/img/1.3.9/img_bdwxch.png" alt="" />
          <div class="right_font">
            <p class="font1">专属特权：</p>
            <div class="font2">
              <p>1.开课通知，开课前15分钟收到开课通知，提前准备</p>
              <p>2.第一时间获取官方活动，产品更新动态</p>
              <p>3.微信扫码，安全快捷登录后台</p>
              <p>4.移动网校管理后台，随时随地查看网校经营概况</p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 更改绑定微信的弹窗 -->
    <el-dialog
      title="账号绑定微信"
      :visible.sync="dialogVisible_cwx"
      :close-on-click-modal="false"
      width="800px"
      class="dialogVisible_cwx"
    >
      <div class="dialogVisible_cwx_main">
        <div class="dialogVisible_cwx_contain">
          <div class="font1">
            请先进行身份验证：验证码已发送到该账号绑定的手机（{{ accountPhone }}
            ），请注意查收
          </div>
          <div class="vertify">
            <div class="font">输入验证码</div>
            <div class="account-input verify-code" style="margin-bottom: 76px">
              <input
                v-model.trim="numCode"
                maxlength="4"
                placeholder="请输入验证码"
              />
              <get-verify-code
                :type="11"
                :value="accountPhone"
                timesName="yzmcode"
                :otherWay="false"
                @callback="nextTime2 = arguments[0]"
              >
                <button class="code register-code" :disabled="nextTime2 > 0">
                  {{ nextTime2 > 0 ? `${nextTime2}s后重试` : '获取验证码' }}
                </button>
              </get-verify-code>
            </div>
          </div>
          <div class="footer">
            <el-button
              type=""
              style="width: 107px"
              @click="dialogVisible_cwx = false"
              size="medium"
            >
              取消
            </el-button>
            <el-button
              type="primary"
              style="width: 107px"
              @click="netstep"
              size="medium"
            >
              下一步
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import changePhotoimg from '@cm/base/changePhotoimg'
// //本地上传弹窗
// import upload from '@cm/base/UploadtextFile'
// //图片组件
// import addpicture from '@cm/base/addpicture'
import getVerifyCode from '@/components/GetVerifyCode'
import bg from '@/mixin/background'
import modifyName from './modifyName'
import modifyPassword from './modifyPassword'
// import UploadOneImgComponent from '@/components/UploadOneImg'
import { mapState, mapGetters } from 'vuex'
import { logOut } from '@ass/js/cookie'

export default {
  name: 'accountSetting',

  mixins: [bg],

  components: {
    changePhotoimg,
    // addpicture,
    // upload,
    modifyName,
    modifyPassword,
    // UploadOneImgComponent,
    getVerifyCode,
  },

  data() {
    // data：uid 用户id uname 用户昵称 uphoto 用户头像 upwd 密码  wx_nickname 微信名称
    return {
      // 验证码登录倒计时
      nextTime2: 0,

      //输入的验证码
      numCode: '',

      dialogVisible_cwx: false, //更换绑定微信的弹窗

      requestCode: '',

      qrcode: '',

      dialogVisible_wx: false, //绑定微信

      upImgUrl: '',

      photoComplete: {},

      showDetail: false,

      showPassDetail: false,

      accountInfo: {},

      uid: '',

      detailCate: '',

      accountName: '',

      accountPhone: '',

      showPassword: '................',

      orginPassword: '',

      accounWxName: '',

      wx_bind: '',

      adminNumber: 1,

      // 离开网校文本
      awaySchollText: '',

      showAwayScholl: false,
    }
  },

  created() {
    this.getSimpleInfo()
  },

  destroyed() {
    clearInterval(this.timer)
  },

  computed: {
    ...mapState(['userInfo']),
    // ...mapState('netSchoolDecration', [
    //   'changgePicturestatus',
    //   'group_id1',
    //   'uploadStart',
    // ]),
    ...mapGetters(['isJiGouAdmin']),
  },

  methods: {
    complete() {
      this.getSimpleInfo()
    },
    // ...mapMutations('netSchoolDecration', [
    //   'uploadStartChange',
    //   'uploadStartChange',
    //   'changgePicture',
    //   'groupid1Change',
    // ]),

    //验证输入的验证码是否正确
    netstep() {
      this.$http({
        url: '/user/verifyMobile',
        data: {
          phone: this.accountPhone,
          verify_code: this.numCode,
        },
        callback: () => {
          this.dialogVisible_cwx = false
          this.numCode = ''
          this.bind_wx()
        },
        error: () => {
          this.numCode = ''
        },
      })
    },
    //更换绑定的微信
    change_wx() {
      this.dialogVisible_cwx = true
    },
    //监听二维码扫描
    requestCallback() {
      // console.log('Math', Math.random)
      const self = this
      clearInterval(self.timer)
      self.timer = setInterval(() => {
        self.$http({
          name: 'requreStatus',
          url: '/user/requreStatus',
          data: {
            ql_id: self.requestCode,
          },
          callback({ isRegistry }) {
            // 停止掉定时器
            clearInterval(self.timer)
            if (isRegistry) {
              self.$message({
                showClose: true,
                message: '账号绑定微信成功！',
                type: 'success',
              })
              self.getSimpleInfo()
              self.dialogVisible_wx = false
            } else {
              self.$message({
                showClose: true,
                message: '绑定失败！该微信已绑定其他账号',
                type: 'error',
              })
              self.getCode()
            }
          },
        })
      }, 2000)
    },

    //获取微信绑定二维码
    getCode(isRequest) {
      const self = this
      self.$http({
        name: 'qrcode',
        url: '/wxCustom/qrcode',
        callback({ data }) {
          self.downloadUrl = data.pcClientDownload
          self.qrcode = data.qrcodeUrl
          // 记住微信扫码code
          self.requestCode = data.code
          if (!isRequest) {
            // 开启2S去后台获取状态
            self.requestCallback()
          }
        },
      })
    },
    //绑定微信
    bind_wx() {
      this.getCode()
      this.dialogVisible_wx = true
    },

    // // 获取机构的一些信息
    getSimpleInfo() {
      let self = this
      self.$http({
        name: 'getSimpleUserInfo',
        url: '/User/getSimpleUserInfo',
        callback: (res) => {
          this.accountInfo = Object.assign({}, this.accountInfo, res.data)
          this.uid = this.accountInfo.uid
          this.accountName = this.accountInfo.uname
          this.accountPhone = this.accountInfo.umobile
          this.accounWxName = this.accountInfo.wx_nickname
          this.wx_bind = this.accountInfo.wx_bind
          this.orginPassword = this.accountInfo.upwd
          this.upImgUrl = this.accountInfo.uphoto
          this.adminNumber = this.accountInfo.adminNumber
          if (!this.wx_bind) {
            this.getCode()
            this.dialogVisible_wx = true
          }
        },
      })
    },

    /**
     * @desc 修改用户名信息
     * @param accountName 用户名
     * @returns
     */
    toggleModifyConfirm() {
      this.showDetail = !this.showDetail
    },

    togglePassConfirm() {
      this.showPassDetail = !this.showPassDetail
    },

    /**
     * @desc 获取修改后的用户名
     * @param data 用户名
     * @returns
     *
     */
    getModifyAccountname(data) {
      this.accountName = data
    },

    // 获取修改后的密码
    getModifyAccountPassword(data) {
      this.orginPassword = data
    },

    awaySchollClose() {
      this.showAwayScholl = false
      this.awaySchollText = ''
    },

    // 离开网校
    awayScholl() {
      if (this.isJiGouAdmin && this.adminNumber == 1) {
        this.$confirm(
          '当前网校只有您一个管理员身份，无法离开网校，请在老师设置中设置一个管理员角色',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        )
      } else {
        this.showAwayScholl = true
      }
    },

    awaySchollCallback() {
      if (this.awaySchollText === '确认离开') {
        this.$http({
          name: 'removeTeacher',
          url: '/Organ/removeTeacher',
          data: {
            tid: this.uid,
            type: 1,
          },
          callback: () => {
            logOut()
          },
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.dialogVisible_cwx_main {
  .dialogVisible_cwx_contain {
    width: 602px;
    margin: 110px auto 0;
    .font1 {
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      text-align: center;
      margin-bottom: 30px;
    }
    .vertify {
      margin-bottom: 107px;
      .font {
        margin-top: 12px;
        float: left;
        margin-right: 17px;
        font-size: 13px;
        color: #333333;
        line-height: 13px;
      }
      /**验证码**/
      .account-input {
        margin-bottom: 20px;
        &.mt {
          margin-top: 36px;
        }
        input {
          color: #333;
          width: 100%;
          height: 36px;
          display: block;
          font-size: 14px;
          padding-left: 10px;
          border-radius: 2px;
          box-sizing: border-box;
          border: 1px solid #dddddd;
          &:focus {
            border-color: #0aa29b;
          }
          &::-webkit-input-placeholder {
            color: #9b9b9b;
            font-size: 12px;
          }
        }
        i {
          position: absolute;
          left: 0;
          bottom: -17px;
          font-size: 12px;
          color: rgba(255, 53, 53, 1);
          line-height: 16px;
          opacity: 0;
        }
      }
      .warnning-input {
        position: relative;
        input {
          border: 1px solid #ff3535;
          &:focus {
            border-color: #ff3535;
          }
        }
        i {
          opacity: 1;
        }
      }
      /**验证码***/
      .verify-code {
        display: flex;
        align-items: center;
        input {
          flex: 1;
        }
        .code {
          width: 82px;
          height: 36px;
          border-radius: 1px;
          margin-left: 13px;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .register-code2 {
          font-size: 12px;
          color: #fff;
          line-height: 36px;
          border: 1px solid rgba(10, 162, 155, 1);
          background: rgba(10, 162, 155, 1);
        }
        .register-code {
          font-size: 12px;
          color: rgba(10, 162, 155, 1);
          line-height: 36px;
          border: 1px solid rgba(10, 162, 155, 1);
          background: none;
        }
      }
    }
    .footer {
      text-align: center;
    }
  }
}
.dialogVisible_cwx {
  & ::v-deep .el-dialog__header {
    padding-bottom: 0;
  }
}
.dialog_wx {
  display: flex;
  .left {
    width: 316px;
    height: 360px;
    border-right: 1px solid #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: center;
    .left_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .left_font {
        margin-top: 21px;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
      }
    }
  }
  .right {
    padding-left: 74px;
    width: calc(800px - 317px);
    img {
      width: 263px;
      height: 182px;
    }
    .right_font {
      .font1 {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 14px;
        margin: 15px 0;
      }
      .font2 {
        font-size: 15px;
        color: #333333;
        line-height: 31px;
      }
    }
  }
}
.awayScholl {
  font-size: 14px;
  cursor: pointer;
  color: rgba(255, 53, 53, 1);
  line-height: 19px;
  text-align: center;
}
.account-contain {
  .photo_contain {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
    .photo {
      cursor: pointer;
      position: relative;
      border-radius: 50%;
      height: 100px;
      width: 100px;
      background-color: #c0c4cc;
      .changePhotoimg {
        position: absolute;
        top: 0;
      }
      .icon {
        position: absolute;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        bottom: -11px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .avatars {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 41px auto 31px auto;
    border-radius: 50%;
    margin: 40px 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
      height: 100%;
    }
    .upImg {
      position: absolute;
      width: 26px;
      height: 26px;
      bottom: -13px;
      left: 50%;
      transform: translateX(-50%);
    }
    .uploadBtn {
      display: none;
    }
    ::v-deep .uploadOneImgRes {
      max-height: 100%;
    }
  }
  .account-info {
    background: #ffffff;
    box-sizing: border-box;
    height: 339px;
    display: flex;
    margin: 20px 20px 0;
    justify-content: center;
    .account-box {
      margin: 48px auto 60px;
      .mb {
        margin-bottom: 30px;
      }
      .account-item-title {
        display: inline-block;
        width: 84px;
        height: 19px;
        font-size: 14px;

        color: rgba(102, 102, 102, 1);
        line-height: 19px;
        margin-right: 46px;
      }
      .account-item-content {
        display: inline-block;
        width: 274px;
        height: 21px;
        font-size: 16px;

        color: rgba(74, 74, 74, 1);
        line-height: 21px;
        margin-right: 28px;
      }
      .inputlth {
        width: 274px;
      }
      .modify {
        display: inline-block;
        height: 19px;
        font-size: 14px;

        color: rgba(10, 162, 155, 1);
        line-height: 19px;
        cursor: pointer;
      }
    }
  }
}
/deep/ .awayScholl .el-dialog__body {
  padding: 0;
}
</style>
