<template>
  <fade>
    <div class="fadeConfirmChild pop-content">
      <div class="title">
        修改昵称
        <!-- <i class="close" @click="close"></i> -->
      </div>
      <div class="txt">昵称</div>
      <input v-model.trim="oldName" placeholder="请输入用户名" class="inputbox" maxlength="16" />
      <div class="confirmItem">
        <span @click="cancleModify" class="cancle">取消</span>
        <button @click="emitMModifyName">确定</button>
      </div>
    </div>
  </fade>
</template>
<script>
import fade from '@/components/FadeAnimation'

export default {
  name: 'modifyName',

  props: {
    modifyAccountName: String,
    uid: [String, Number]
  },

  components: {
    fade
  },

  data() {
    return {
      oldName: ''
    }
  },

  created() {
    // 将父组件传过来的值赋值给一个新的变量
    this.oldName = this.modifyAccountName
  },

  methods: {
    close() {
      this.$emit('close')
    },
    //取消修改名称
    cancleModify() {
      this.close()
    },
    //点击确定后将修改的值传给父组件
    emitMModifyName() {
      if (this.oldName.length == 0) {
        this.$root.prompt('请输入用户名')
        return
      }
      let self = this
      self.$http({
        name: 'editUserName',
        url: '/User/editUserSimpleInfo',
        data: {
          uid: self.uid,
          uname: self.oldName
        },
        callback: ({ code }) => {
          if (code == 200) {
            this.$emit('setModifyAccountName', this.oldName)
            this.close()
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pop-content {
  width: 480px;
  height: 248px;
  background-color: #fff;
  box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.07);
  padding: 20px 30px;
  box-sizing: border-box;
  input {
    height: 21px;
    font-size: 16px;

    color: rgba(51, 51, 51, 1);
    line-height: 21px;
  }
  .title {
    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 10px;
      top: 16px;
      cursor: pointer;
      background: url('../../assets/img/ico_close2.png') no-repeat;
    }
    height: 24px;
    font-size: 18px;

    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 24px;
    margin-bottom: 66px;
  }
  .txt {
    height: 16px;
    font-size: 12px;

    color: rgba(153, 153, 153, 1);
    line-height: 16px;
  }
  .inputbox {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-color: transparent transparent;
    border-bottom: 1px solid rgba(204, 204, 204, 1);
    margin-bottom: 25px;
  }

  .confirmItem {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .cancle {
      font-size: 14px;

      color: rgba(51, 51, 51, 1);
    }
    button {
      width: 68px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      border: none;
      color: #fff;
      margin-left: 30px;
      background: rgba(10, 162, 155, 1);
      border-radius: 2px;
    }
  }
}
</style>
