<template>
  <fade>
    <div class="fadeConfirmChild pop-content">
      <div class="title">
        修改密码
        <!-- <i class="close" @click="close"></i> -->
      </div>
      <div class="txt">手机号</div>
      <div class="phone">{{ mobilePhone }}</div>

      <div class="txt">请输入验证码</div>
      <div class="modiify-code-box" :class="{ 'warnning-input': numCodeWarnning }">
        <input v-model.trim="numCode" placeholder="请输入验证码" maxlength="4" />
        <get-verify-code :type="8" :value="mobilePhone" timesName="modifyPhoneCode" :otherWay="false" @callback="nextTime = arguments[0]">
          <button class="code" :disabled="phoneWarnning">
            {{ nextTime > 0 ? `${nextTime}s后重试` : '获取验证码' }}
          </button>
        </get-verify-code>
        <i>验证码有误</i>
      </div>

      <div class="txt">请输入新密码</div>
      <div class="modiify-code-box" :class="{ 'warnning-input': passwordWarnning }">
        <input v-model.trim="password" placeholder="请输入新密码" />
        <i>请输入长度为6~16个字符的密码，标点符号无效</i>
      </div>

      <div class="txt">请再次输入新密码</div>
      <div class="modiify-code-box" :class="{ 'warnning-input': newPasswordWarnning }">
        <input v-model.trim="newPassword" placeholder="请再次输入新密码" />
        <i>密码不一致</i>
      </div>

      <div class="confirmItem">
        <!-- <span @click="forgetPassword" class="cancle">忘记密码</span> -->
        <div>
          <span @click="cancleModify" class="cancle">取消</span>
          <button @click="emitMModifyPassword">确定</button>
        </div>
      </div>
    </div>
  </fade>
</template>
<script>
import fade from '@/components/FadeAnimation'
// import { FromVerify } from "@/assets/js/fromVerify";
import getVerifyCode from '@/components/GetVerifyCode'

export default {
  name: 'modifyPassword',

  props: {
    // modifyAccountPassword: String,
    mobilePhone: [String, Number],
    uid: [String, Number]
  },

  components: {
    fade,
    getVerifyCode
  },

  data() {
    return {
      // 用户账号信息
      verifyCode: '/wxCustom/VerificationCode',
      code: '',
      password: '',
      newPassword: '',
      numCode: '',
      phoneWarnning: false,
      passwordWarnning: false,
      numCodeWarnning: false,
      codeWarnning: false,
      newPasswordWarnning: false,
      isLoading: false,
      //倒计时
      nextTime: ''
    }
  },

  watch: {
    password(value) {
      if (value) {
        this.passwordWarnning = this.checkPhoneOrPassword(value, 2)
      }
    },
    numCode(value) {
      if (value) {
        this.numCodeWarnning = this.checkPhoneOrPassword(value, 3)
      }
    },
    newPassword(value) {
      if (value) {
        if (value !== this.password) {
          this.newPasswordWarnning = true
        } else {
          this.newPasswordWarnning = false
        }
      }
    }
  },

  created() {
    // 将父组件传过来的值赋值给一个新的变量
    this.originPassword = this.modifyAccountPassword
  },

  methods: {
    /**
     * @name: checkPhoneOrPassword
     * @test: test font
     * @msg: 验证手机号或者密码
     * @param {value,type:1手机号 2密码}
     * @return: Boolean true为不匹配
     */
    checkPhoneOrPassword(value, type) {
      let res = false
      if (type == 1) {
        res = !/^1[2-9]\d{9}$/.test(value)
      } else if (type == 2) {
        res = !/^[a-zA-Z0-9]{6,16}$/.test(value)
      } else if (type == 3) {
        res = !/^\d{4}$/.test(value)
      } else {
        res = !/^[a-zA-Z0-9]{4}$/.test(value)
      }
      return res
    },
    close() {
      this.$emit('close')
    },

    //取消修改名称
    cancleModify() {
      this.close()
    },
    //点击确定后将修改的值传给父组件
    emitMModifyPassword() {
      const self = this
      self.numCodeWarnning = self.checkPhoneOrPassword(self.numCode, 3)

      self.passwordWarnning = self.checkPhoneOrPassword(self.password, 2)

      if (self.newPassword) {
        if (self.newPassword !== self.password) {
          self.newPasswordWarnning = true
        } else {
          self.newPasswordWarnning = false
        }
      } else {
        self.newPasswordWarnning = true
      }
      // 对的 警告就是false

      if (self.numCodeWarnning || self.passwordWarnning || self.newPasswordWarnning) {
        return
      }
      self.$http({
        name: 'editUserName',
        url: '/User/savePassword',
        data: {
          uid: self.uid,
          umobile: self.mobilePhone,
          code: self.numCode,
          //  输入：uid：用户id    umobile：手机号 upwd： 密码  confirm_pwd：确认密码  code：验证码
          upwd: self.password,
          confirm_pwd: self.newPassword
        },
        callback: ({ code }) => {
          if (code == 200) {
            self.$emit('setModifyAccountPassword', self.newPassword)
            self.close()
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pop-content {
  width: 480px;
  height: 505px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.07);
  padding: 20px 30px;
  box-sizing: border-box;
  .phone {
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-bottom: 30px;
    font-size: 16px;

    color: rgba(51, 51, 51, 1);
  }
  input {
    height: 21px;
    font-size: 16px;

    color: rgba(51, 51, 51, 1);
    line-height: 21px;
    border-color: transparent transparent;
    border-bottom: 1px solid rgba(204, 204, 204, 1);
    margin-bottom: 28px;
  }
  .title {
    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 10px;
      top: 16px;
      cursor: pointer;
      background: url('../../assets/img/ico_close2.png') no-repeat;
    }
    height: 24px;
    font-size: 18px;

    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 24px;
    margin-bottom: 66px;
  }
  .txt {
    height: 16px;
    font-size: 12px;

    color: rgba(153, 153, 153, 1);
    line-height: 16px;
  }

  .modiify-code-box {
    position: relative;
    input {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-color: transparent transparent;
      border-bottom: 1px solid rgba(204, 204, 204, 1);
    }
    .code {
      position: absolute;
      top: 0;
      right: 0;
      width: 80px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 2px;
      border: 1px solid rgba(10, 162, 155, 1);
      font-size: 12px;

      color: rgba(10, 162, 155, 1);
      background-color: #fff;
      cursor: pointer;
    }
    i {
      position: absolute;
      left: 0;
      bottom: 7px;
      font-size: 12px;
      color: rgba(255, 53, 53, 1);
      line-height: 16px;
      opacity: 0;
    }
  }

  .warnning-input {
    position: relative;
    i {
      opacity: 1;
    }
  }
  .confirmItem {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .cancle {
      font-size: 14px;

      color: rgba(51, 51, 51, 1);
      cursor: pointer;
    }
    button {
      width: 68px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      border: none;
      color: #fff;
      margin-left: 30px;
      background: rgba(10, 162, 155, 1);
      border-radius: 2px;
      cursor: pointer;
    }
  }
}
</style>
